import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'

export function Badge(props) {
  return (
    <Common.Box
      flex="1"
      display="flex"
      justifyContent={['flex-start', 'flex-end']}
      alignItems="center"
      marginLeft={0}
    >
      <Common.Box
        backgroundColor="badge-background"
        fontSize={0}
        fontWeight="800"
        textAlign="center"
        minWidth="20px"
        padding="0.25em 0.5em"
        borderRadius="100px"
        color="nav"
        fontFamily="head"
      >
        {props.children}
      </Common.Box>
    </Common.Box>
  )
}

Badge.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
}
