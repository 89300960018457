import * as ReactRedux from 'react-redux'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as T from '@rushplay/theme'
import * as Herz from '@rushplay/herz'
import styled from '@emotion/styled'

import * as CombinedSelectors from './combined-selectors'
import * as Inventory from './inventory'
import * as Player from './player'

const Notification = styled('div', {
  shouldForwardProp: Common.noneOf(['hasNotifications', 'backgroundColor']),
})`
  position: relative;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.35em;
    height: 0.35em;
    border-radius: 1em;
    background-color: ${props => T.color(props.backgroundColor)};
    content: '';
    transform: ${props => (props.hasNotifications ? 'scale(1)' : 'scale(0)')};
    transition: transform 0.5s cubic-bezier(0.36, 1.78, 0.55, 0.68);
  }
`

export function MenuNotificationBadge(props) {
  const { authenticated } = Herz.Auth.useSession()
  const hasNotifications = ReactRedux.useSelector(state =>
    CombinedSelectors.hasNotifications(state)
  )
  const hasUnseenInventory = ReactRedux.useSelector(state =>
    Inventory.hasUnseenItems(state.inventory)
  )

  const hasSeenAllAccountDocuments = ReactRedux.useSelector(state =>
    Player.hasSeenAllAccountDocuments(state.player)
  )

  return (
    <Notification
      backgroundColor={
        hasUnseenInventory || !hasSeenAllAccountDocuments
          ? 'nav-badge'
          : 'nav-highlight'
      }
      hasNotifications={
        hasNotifications || (authenticated && !hasSeenAllAccountDocuments)
      }
    >
      {props.children}
    </Notification>
  )
}

MenuNotificationBadge.propTypes = {
  children: PropTypes.node,
}
