import { connect } from 'react-redux'

import * as player from '../player'

import { Balance as Component } from './balance'

export const Balance = connect(state => {
  const balance = player.getBalanceCents(state.player)
  return {
    amountCents: Number.isNaN(balance) ? 0 : balance,
  }
})(Component)
