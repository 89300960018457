import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

import { lazy as loadable } from '@loadable/component'

import { MainMenu } from '../main-menu'
import { PrivateRoute } from '../private-route'

const Logout = loadable(() => import('../logout'))
const PhoneVerificationDrawer = loadable(() =>
  import('../phone-verification-drawer')
)

export function PhoneVerificationContainer() {
  return (
    <React.Fragment>
      <ReactRouter.Route path="/" component={MainMenu} />
      <ReactRouter.Switch>
        <PrivateRoute path="/logout" component={Logout} />
      </ReactRouter.Switch>
      <PhoneVerificationDrawer />
    </React.Fragment>
  )
}

// For @loadable/component
export default PhoneVerificationContainer
